@import '../semantic-ui/site/globals/site.variables';

.ui.container.overlay-content {
    background-color: #f9fbfc;
}

.ui.container.contentcontainer {
    padding: 3% 7%;
    
}

iframe.content-iframe {
    overflow: hidden;
}

@media only screen and (max-width: 1370px) {

    .ui.container.overlay-content {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}