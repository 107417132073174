@import '../semantic-ui/site/globals/site.variables';

#showroom-story-heading {
    font-size: 3.4rem;
    color: white;
    text-align: center;
}

.showroom-story {
    font-size: 1.4rem;
    color: white;
    text-align: center;
    padding: 0.8rem 9rem;
    margin: 0;
}

// .ui.blue.button.about-rx-mill-close {
i.big.icon.about-rx-mill-close {
    position: absolute;
    right: 1.5vh;
    top: 1.5vh;
}

i.big.icon.about-rx-mill-close:hover {
    position: absolute;
    right: 1.5vh;
    top: 1.5vh;
    color: rgba(256,256,256,0.8);
    cursor: pointer;
}

.ui.centered.grid > .column.about-mill {
    text-align: center !important;
    color: #ffffff;
    background-color: rgba(0,124,176,0.8);
    width: 65%;
    top: 6%;
}

.ui.button.enter-showroom {
    background-color: rgba(256,256,256,1);
}

.ui.grid > .column:not(.row) {
    width: 70%;
    padding-top: 3rem;
    padding-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

@media only screen and (max-width: 1370px) {

    .ui.centered.grid > .column.about-mill {
        text-align: center !important;
        color: #ffffff;
        background-color: rgba(0,124,176,0.8);
        width: 95%;
        margin-top: 64px;
        top: 1%;
        bottom: 1%;
        max-height: 85%;
        overflow-y: scroll;
    }

    .showroom-story {
        color: white;
        text-align: center;
        padding: 0.75rem 0.25rem;
        font-size: 1.1rem;
    }

    #showroom-story-heading {
        font-size: 1.9rem;
        color: white;
        text-align: center;
    }

    .ui.button.enter-showroom {
        background-color: rgba(256,256,256,1);
        font-size: medium;
    }

    i.big.icon.about-rx-mill-close {
        font-size: large;
    }
}
