/*******************************
     User Global Variables
*******************************/
#raute-dot-com {
  background-color: #007CB0;
  color: white;
}
#talk-to-an-expert {
  background-color: #00B51A;
  color: white;
}
#expert-talks {
  background-color: #007CB0;
  color: white;
}
#book-a-demo {
  background-color: #007864;
  color: white;
}
#about-rx-mill:hover {
  color: #007CB0;
}
#about-rx-mill-active {
  color: #000000;
}
.ui.menu {
  font-weight: 600;
}
.ui.menu .item {
  font-weight: 600;
}
.ui.secondary.pointing.menu .item.custom-menu-item:hover {
  color: #007CB0;
}
.ui.secondary.pointing.menu .item {
  color: #585858;
}
.ui.secondary.pointing.menu .active.item {
  border-color: #00B51A !important;
  border-width: 2px;
  color: #000000;
  background: #ffffff;
}
.ui.secondary.pointing.menu .active.item :hover {
  border-color: #00B51A !important;
  border-width: 2px;
  color: #007CB0 !important;
}
.ui.small.image.custom-size-logo {
  height: 31px;
  width: auto;
}
.ui.secondary.pointing.menu .item {
  padding-top: 30px;
  padding-bottom: 30px;
}
.ui.secondary.pointing.menu .left.item {
  padding-top: 21px;
  padding-bottom: 23px;
  padding-left: 35px;
}
.ui.pointing.secondary.catch-pointer-events.menu {
  background-color: #fff;
}
.ui.menu a.item#join-live-button:hover {
  background-color: #006a97;
}
.ui.menu a.item#talk-to-an-expert:hover {
  background-color: #009c16;
}
.ui.menu a.item#expert-talks:hover {
  background-color: #006a97;
}
.ui.menu a.item#raute-dot-com:hover {
  background-color: #006a97;
}
@media only screen and (max-width: 1500px) {
  .ui.small.image.custom-size-logo {
    height: 26px;
    width: auto;
  }
  .ui.secondary.pointing.menu .item {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .ui.secondary.pointing.menu .left.item {
    padding-top: 18px;
    padding-bottom: 20px;
    padding-left: 25px;
  }
  .ui.menu {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 1370px) {
  .ui.small.image.custom-size-logo {
    height: 26px;
    width: auto;
  }
  .ui.secondary.pointing.menu .item {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 30px;
  }
  .ui.secondary.vertical.pointing.menu .active.item {
    border-left: 4px solid #00B51A !important;
  }
  .ui.sidebar.menu .item {
    text-align: left;
  }
}
