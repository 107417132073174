.ui.labeled.icon.button.hotspot-button {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 15px;
}
.close-spot-content {
  position: absolute !important;
  right: 1.9vh;
  top: 1.2vh;
}
.close-spot-content > .icon:first-child {
  color: white;
}
.close-spot.content > .icon:first-child :hover {
  color: rgba(0, 0, 0, 0.2);
}
.close-spot-content:hover {
  position: absolute !important;
  cursor: pointer;
}
@keyframes open-hotspot-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.13;
  }
}
.content-hotspot-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  mix-blend-mode: normal;
  opacity: 0.13;
  animation-name: open-hotspot-overlay;
  animation-duration: 0.75s;
}
@media only screen and (max-width: 1370px) {
  .ui.labeled.icon.button.hotspot-button {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 13px;
  }
  .ui.blue.button.hotspot-button {
    font-size: 13px;
  }
}
